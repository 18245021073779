import React, { useEffect } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import { compose } from 'recompose';
import { Flex } from 'reflexbox/styled-components';

import authenticated from 'hoc/authenticated';
import authorized from 'hoc/authorized';

const PartnerDashboardView = () => {
    const router = useRouter();

    useEffect(() => {
        router.push('/partner/applications');
    }, []);

    return (
        <Flex overflow="auto" padding={3}>
            <Link href="/partner/applications">Partner Dashboard</Link>
        </Flex>
    );
};

export const PartnerDashboard: React.FC = compose(
    authenticated,
    authorized(['realtor', 'financialadvisor'])
)(PartnerDashboardView);
