import React from 'react';

import { Trans } from '@lingui/react';
import {
    Box,
    Card,
    Divider,
    Flex,
    Grid,
    Heading,
    Typography,
} from '@nestoca/ui';
import { BiHome } from 'react-icons/bi';
import { useRecoilValue } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { useI18n } from 'providers/i18n/use-i18n';
import { getReferralByApplicationId } from 'store/referrals';
import { Address } from 'types/address';
import {
    OtherMortgage,
    REFERRAL_INTEREST_RATE_TYPE_TRANSLATION_MAP,
    REFERRAL_PAYMENT_FREQUENCY_TRANSLATION_MAP,
    REFERRAL_PROPERTY_TYPE_TRANSLATION_MAP,
    ReferralAddress,
    ReferralApplicant,
} from 'types/referral';
import { formatAddress } from 'utils';

import styles from './other-mortgages.module.scss';

type Props = {
    applicationId: number;
};

const mapReferralAddressToAddress = (address: ReferralAddress): string => {
    // Referral Address don't have countryCode / stateCode and streetNumber they return province and number instead
    const mappedAddress: Address = {
        ...address,
        countryCode: '',
        stateCode: address?.province || '',
        streetNumber: address?.number || '',
    };

    return formatAddress(mappedAddress);
};

export const OtherMortgages = ({ applicationId }: Props) => {
    const { i18n } = useI18n();

    const referral = useRecoilValue(
        getReferralByApplicationId({ applicationId })
    );

    const { mainApplicantId, applicants } = referral || {
        applicants: [],
        mainApplicantId: null,
    };

    const applicantsWithOtherMortgages = applicants.filter(
        (referral) => !!referral?.externalData?.other_mortgages?.length
    );

    if (!applicantsWithOtherMortgages?.length) {
        return null;
    }

    return (
        <Box className={styles['other-mortgages']}>
            <Heading as="h2" size={5} weight={4}>
                <Trans id="referral.otherMortgages" />
            </Heading>

            <Card>
                {applicantsWithOtherMortgages.map(
                    (referral: ReferralApplicant, index: number) => (
                        <React.Fragment
                            key={`${referral?.applicantId}-${index}`}
                        >
                            {referral?.externalData?.other_mortgages.map(
                                (otherMortgage: OtherMortgage, i: number) => (
                                    <React.Fragment
                                        key={`${otherMortgage?.existingLoanNumber}-${i}`}
                                    >
                                        <Typography weight={7}>
                                            {i18n._(
                                                `${
                                                    referral.applicantId ===
                                                    mainApplicantId
                                                        ? 'mainApplicant'
                                                        : 'coApplicant'
                                                }`
                                            )}
                                        </Typography>
                                        <Flex
                                            align="center"
                                            justify="between"
                                            gap={4}
                                            as="header"
                                        >
                                            <Flex align="center" gap={2}>
                                                <BiHome size={30} />
                                                <Typography weight={7}>
                                                    {mapReferralAddressToAddress(
                                                        otherMortgage?.currentPropertyAddress
                                                    )}
                                                </Typography>
                                            </Flex>
                                        </Flex>
                                        <Grid className={styles.grid}>
                                            <CellData
                                                tx="mortgageAmount"
                                                fieldType="money"
                                                value={
                                                    otherMortgage?.currentMortgageAmount ||
                                                    ''
                                                }
                                            />
                                            <CellData
                                                tx="mortgageBalance"
                                                fieldType="money"
                                                value={
                                                    otherMortgage?.currentMortgageBalance ||
                                                    ''
                                                }
                                            />
                                            <CellData
                                                tx="appraisalValue"
                                                fieldType="money"
                                                value={
                                                    otherMortgage?.appraisalValue ||
                                                    ''
                                                }
                                            />
                                            <CellData
                                                tx="downPayment"
                                                fieldType="money"
                                                value={
                                                    otherMortgage?.downPayment ||
                                                    ''
                                                }
                                            />
                                            <CellData
                                                tx="paymentAmount"
                                                fieldType="money"
                                                value={
                                                    otherMortgage?.paymentAmount ||
                                                    ''
                                                }
                                            />
                                            <CellData
                                                tx="purchasePrice"
                                                fieldType="money"
                                                value={
                                                    otherMortgage?.purchasePrice ||
                                                    ''
                                                }
                                            />
                                            <CellData
                                                tx="mortgageBalanceDate"
                                                fieldType="date"
                                                value={
                                                    otherMortgage?.currentMortgageBalanceDate ||
                                                    ''
                                                }
                                            />
                                            <CellData
                                                tx="fundingDate"
                                                fieldType="date"
                                                value={
                                                    otherMortgage?.fundingDate ||
                                                    ''
                                                }
                                            />
                                            <CellData
                                                tx="maturityDate"
                                                fieldType="date"
                                                value={
                                                    otherMortgage?.maturityDate ||
                                                    ''
                                                }
                                            />
                                            <CellData
                                                tx="existingLoanNumber"
                                                value={
                                                    otherMortgage?.existingLoanNumber ||
                                                    ''
                                                }
                                            />
                                            <CellData
                                                tx="interestRate"
                                                fieldType="percentage"
                                                value={
                                                    otherMortgage?.interestRate ||
                                                    ''
                                                }
                                            />
                                            <CellData
                                                tx="interestRateType"
                                                value={i18n._(
                                                    REFERRAL_INTEREST_RATE_TYPE_TRANSLATION_MAP[
                                                        otherMortgage
                                                            ?.interestRateType
                                                    ] || ''
                                                )}
                                            />
                                            <CellData
                                                tx="mlsNumber"
                                                value={
                                                    otherMortgage?.mlsNumber ||
                                                    ''
                                                }
                                            />
                                            <CellData
                                                tx="paymentFrequency"
                                                value={i18n._(
                                                    REFERRAL_PAYMENT_FREQUENCY_TRANSLATION_MAP[
                                                        otherMortgage
                                                            ?.paymentFrequency
                                                    ] || ''
                                                )}
                                            />
                                            <CellData
                                                tx="propertyType"
                                                value={i18n._(
                                                    REFERRAL_PROPERTY_TYPE_TRANSLATION_MAP[
                                                        otherMortgage
                                                            ?.propertyType
                                                    ] || ''
                                                )}
                                            />
                                            <CellData
                                                tx="remainingTermDays"
                                                fieldType="simpleNumber"
                                                value={
                                                    otherMortgage?.remainingTermDays ||
                                                    ''
                                                }
                                            />
                                        </Grid>
                                        {index <
                                            referral?.externalData
                                                ?.other_mortgages.length -
                                                1 && <Divider />}
                                    </React.Fragment>
                                )
                            )}
                        </React.Fragment>
                    )
                )}
            </Card>
        </Box>
    );
};
