import { Card, Divider, Flex, Grid } from '@nestoca/ui';
import cn from 'classnames';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { useRecoilValue } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { Text } from 'components/text/text';
import { getReferralReferrer } from 'store/referrals';

import dashBoardStyles from '../broker-dashboard.module.scss';

import styles from './referrer-contact.module.scss';

type ReferrerContactProps = {
    applicationId: number;
};

export const ReferrerContact = ({ applicationId }: ReferrerContactProps) => {
    const referrerContact = useRecoilValue(
        getReferralReferrer({ applicationId })
    );

    return (
        <>
            <Card
                className={cn(
                    styles['referrer-contact__card'],
                    dashBoardStyles.card
                )}
                title={'asset.property'}
            >
                <Flex
                    className={dashBoardStyles.header}
                    align="center"
                    justify="between"
                    gap={4}
                    as="header"
                >
                    <Flex align="center" gap={2}>
                        <BsArrowCounterclockwise size={30} />
                        <Text
                            fontWeight={'bold'}
                            tx={'referral.referrerInfo'}
                        />
                    </Flex>
                </Flex>
                <Divider className={dashBoardStyles['main-divider']} />
                <Flex className={styles.body} direction="column">
                    <Grid className={styles.grid}>
                        <CellData
                            tx={'First Name'}
                            value={referrerContact?.firstName || ''}
                        />
                        <CellData
                            tx={'Last Name'}
                            value={referrerContact?.lastName || ''}
                        />
                        <CellData
                            tx={'email'}
                            value={referrerContact?.email || ''}
                            withCopy
                        />
                        <CellData
                            tx={'Phone'}
                            value={referrerContact?.phone || ''}
                        />
                    </Grid>
                    <Divider />
                    <Grid className={styles.grid}>
                        <CellData
                            tx={'referral.department'}
                            value={
                                referrerContact?.externalData?.department || ''
                            }
                        />
                        <CellData
                            tx={'referral.id'}
                            value={referrerContact?.externalData?.id || ''}
                        />
                        <CellData
                            tx={'referral.title'}
                            value={referrerContact?.externalData?.title || ''}
                        />
                        <CellData
                            tx={'referral.username'}
                            value={
                                referrerContact?.externalData?.username || ''
                            }
                        />
                    </Grid>
                </Flex>
            </Card>
        </>
    );
};
