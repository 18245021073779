import { Suspense } from 'react';

import { Trans } from '@lingui/react';
import { Box, Button, Card, Divider, Flex, Grid } from '@nestoca/ui';
import { BsHouseDoor } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import { useRecoilValue } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { MainApplicationTypeLabel } from 'components/dashboard-header/dashboard-header';
import { Text } from 'components/text/text';
import { LENDER, PROPERTY_PURPOSE_LABEL } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { getPropertySelectors, selectedApplication } from 'store/applications';
import { formatAddress } from 'utils';
import { getApplicationMainType } from 'utils/application-type';
import { formatDate } from 'utils/property';

import dashBoardStyles from '../broker-dashboard.module.scss';

import styles from './property-card.module.scss';

import type { PropertyModalProps } from 'components/dashboard/broker-dashboard-new/property-card/property-modal';

export const PropertyDefaultFields = ({
    showViewMore = false,
}: {
    showViewMore?: boolean;
}) => {
    const { i18n } = useI18n();

    const { property, type } = useRecoilValue(selectedApplication);

    const { mainType, isNewApplication } = getApplicationMainType(type);

    const { open: openPropertyModal } = useModal<PropertyModalProps>(
        'dashBoardPropertyModal'
    );

    return (
        <>
            <Box>
                <Flex className={dashBoardStyles.header}>
                    <BsHouseDoor size={30} />
                    {showViewMore ? (
                        <Flex className={styles.viewMore}>
                            <Text fontWeight={'bold'} tx={'asset.property'} />
                            {showViewMore && (
                                <Button
                                    variant="ghost"
                                    onClick={() => openPropertyModal()}
                                >
                                    <Trans id="viewMore" />
                                </Button>
                            )}
                        </Flex>
                    ) : (
                        <Grid>
                            <Text tx={'asset.property'} />
                            <Text
                                fontWeight={'bold'}
                                tx={MainApplicationTypeLabel(i18n)[mainType]}
                            />
                        </Grid>
                    )}
                </Flex>
            </Box>
            <Divider />

            <Grid className={styles.grid}>
                <CellData
                    tx={'dashboardHeader.propertyPurpose'}
                    value={PROPERTY_PURPOSE_LABEL(i18n)[property?.purpose]}
                />
                {!isNewApplication && (
                    <>
                        <EquityContainer />
                        <CellData
                            tx={'lender'}
                            value={LENDER(i18n)[property?.mortgages[0]?.lender]}
                        />

                        <CellData
                            tx={'maturityDate'}
                            value={formatDate(
                                property?.mortgages[0]?.maturityDate,
                                i18n.locale
                            )}
                        />
                    </>
                )}
                <CellData
                    tx={'address'}
                    value={formatAddress(property?.address)}
                />
            </Grid>
        </>
    );
};

export const PropertyCardContainer = () => (
    <Card className={dashBoardStyles.card} title={'asset.property'}>
        <PropertyDefaultFields showViewMore />
    </Card>
);

const EquityView = () => {
    const { equity } = useRecoilValue(getPropertySelectors);

    return <CellData fieldType="money" tx={'equity'} value={equity} />;
};

const EquityContainer = () => (
    <Suspense fallback={<Skeleton />}>
        <EquityView />
    </Suspense>
);
