import React, { Suspense } from 'react';

import { Trans } from '@lingui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Link from 'next/link';
import { constSelector, useRecoilValue } from 'recoil';
import { compose } from 'recompose';
import { Box, Flex } from 'reflexbox/styled-components';

import { Card } from 'components/card';
import { DashboardHeader } from 'components/dashboard-header/dashboard-header';
import { GoogleMaps } from 'components/google-maps/google-maps';
import { Grid } from 'components/grid/grid';
import { EntitiesModalWrapper } from 'components/modals/entities-modal-wrapper';
import { RateTile } from 'components/rate-tile';
import authenticated from 'hoc/authenticated';
import authorized from 'hoc/authorized';
import {
    currentApplicationIdState,
    getSelectedProduct,
    selectedApplication,
} from 'store/applications';
import { singleRateState } from 'store/atom';
import { isContextualSidebarOpenState } from 'store/ui';
import { useEditing } from 'utils/use-editing';
import { EditingProvider } from 'utils/use-editing-context';

import { SidebarDashboard } from '../sidebar-dashboard';
import UnderwriterDashboardNew from '../underwriter-dashboard-new/underwriter-dashboard-new';

import { UnderwriterClientSnapshot } from './underwriter-client-snapshot';

const UnderwriterDashboardView = () => {
    const { enhancedUnderwriterDashboard } = useFlags();

    const editing = useEditing();
    const application = useRecoilValue(selectedApplication);
    const currentApplicationId = useRecoilValue(currentApplicationIdState);
    const isContextualSidebarOpen = useRecoilValue(
        isContextualSidebarOpenState
    );
    const selectedProduct = useRecoilValue(
        getSelectedProduct(currentApplicationId)
    );

    const selectedRate = useRecoilValue(
        selectedProduct?.productId
            ? singleRateState(selectedProduct?.productId)
            : constSelector(null)
    );

    return enhancedUnderwriterDashboard ? (
        <UnderwriterDashboardNew applicationId={application.id} />
    ) : (
        <EditingProvider {...editing}>
            <EntitiesModalWrapper>
                <Grid
                    gridTemplateColumns={
                        isContextualSidebarOpen ? 'auto 350px' : 'auto 10px'
                    }
                    gridGap={20}
                >
                    <Grid>
                        <Box mt={4}>
                            <UnderwriterClientSnapshot />
                        </Box>
                        <DashboardHeader />

                        <Flex justifyContent="center">
                            {selectedRate ? (
                                <RateTile
                                    product={selectedRate}
                                    flexGrow={1}
                                    showSelectProduct={false}
                                    width="340px"
                                    maxWidth="340px"
                                    marginRight={[null, null, '14px']}
                                    marginBottom="14px"
                                />
                            ) : (
                                <Card
                                    flexGrow={1}
                                    width="340px"
                                    maxWidth="340px"
                                    marginRight={[null, null, '14px']}
                                    marginBottom="14px"
                                >
                                    <Flex
                                        css={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: 20,
                                            fontSize: 18,
                                            fontWeight: 600,
                                            height: '100%',
                                        }}
                                    >
                                        <Box
                                            css={{
                                                marginLeft: 10,
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Trans
                                                id="noProductSelected"
                                                components={[
                                                    <Link
                                                        key="noProductSelected.link.rate"
                                                        href="/rates"
                                                    />,
                                                ]}
                                            />
                                        </Box>
                                    </Flex>
                                </Card>
                            )}
                            <GoogleMaps
                                flexGrow={1}
                                width="340px"
                                maxWidth="340px"
                                marginRight={[null, null, '14px']}
                                marginBottom="14px"
                                applicationId={application.id}
                            />
                        </Flex>
                    </Grid>

                    <Grid>
                        <SidebarDashboard applicationId={application.id} />
                    </Grid>
                </Grid>
            </EntitiesModalWrapper>
        </EditingProvider>
    );
};

const UnderwriterDashboardContainer = () => (
    <Suspense fallback={null}>
        <UnderwriterDashboardView />
    </Suspense>
);

export const UnderWriterDashboard: React.FC = compose(
    authenticated,
    authorized('broker')
)(UnderwriterDashboardContainer);
