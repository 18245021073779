import { Card, Divider, Flex } from '@nestoca/ui';
import { BsPeople } from 'react-icons/bs';
import { useRecoilValue } from 'recoil';
import { Box } from 'reflexbox/styled-components';

import { Text } from 'components/text/text';
import {
    currentApplicationIdState,
    getApplicantsList,
} from 'store/applications';

import dashBoardStyles from '../broker-dashboard.module.scss';

import { ApplicantCard } from './applicant-card';

export const ApplicantCardContainer = () => {
    const applicationId = useRecoilValue(currentApplicationIdState);

    const applicants = useRecoilValue(getApplicantsList(applicationId));

    return (
        <Card className={dashBoardStyles.card}>
            <Flex className={dashBoardStyles.header}>
                <BsPeople size={30} />
                <Text tx="applicants" fontWeight="700" fontSize="16px" />
            </Flex>
            <Divider />
            <Box css={{ padding: '0 var(--spacing-4)' }}>
                {applicants.map((applicant) => (
                    <ApplicantCard
                        applicant={applicant}
                        key={applicant.applicantId}
                    />
                ))}
            </Box>
        </Card>
    );
};
