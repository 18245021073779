import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { useRouter } from 'next/router';
import { useForm, FormProvider } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { Box } from 'reflexbox/styled-components';

import { FormActions } from 'components/agreements/form-actions';
import { Card } from 'components/card';
import { SelectControl } from 'components/rates-filter/filter-fields';
import { useToasts } from 'components/toast';
import { client as apiClient } from 'libs/api';
import { useAuth } from 'providers/auth';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    currentApplicationIdState,
    useTransactionTypeOptions,
    useRefreshApplications,
} from 'store/applications';
import yup from 'validations/yup-extended';

import type { FormValues } from 'components/cmhc/types';

const validationSchema = (i18n) =>
    yup.object().shape({
        applicationType: yup.string().required(i18n._('error.fieldRequired')),
    });

type CreateApplicationFormProps = { redirect?: boolean };

const CreateApplicationForm: React.FunctionComponent<
    CreateApplicationFormProps
> = ({ redirect }) => {
    const { i18n } = useI18n();

    const { addToast } = useToasts();

    const { client } = useAuth();

    const { push } = useRouter();

    const transactionTypeOptions = useTransactionTypeOptions();

    const setCurrentApplicationIdState = useSetRecoilState(
        currentApplicationIdState
    );

    const refreshApplications = useRefreshApplications();

    const methods = useForm<FormValues>({
        resolver: yupResolver(validationSchema(i18n)),
    });

    const onSubmit = async (values: any) => {
        try {
            const { data } = await apiClient.createApplication(
                values.applicationType,
                client.subPartnerId
            );

            await refreshApplications();

            addToast(i18n._('successfullySaved'), {
                appearance: 'success',
            });

            if (redirect && data?.id) {
                setCurrentApplicationIdState(data.id);

                push(`/applications/${data.id}`);
                return;
            }
        } catch (error) {
            addToast(`Error: ${i18n._({ id: 'failedToSave' })}`, {
                appearance: 'error',
            });
        }
    };

    return (
        <Card
            css={css({
                flexDirection: 'column',
                width: '600px',
                boxShadow: ['md', '2xl'],
                marginTop: 3,
            })}
        >
            <FormProvider {...methods}>
                <Box as="form" onSubmit={methods.handleSubmit(onSubmit)}>
                    <h1 css={css({ marginBottom: 20 })}>
                        <Trans id="createApplication.title" />
                    </h1>

                    <SelectControl
                        id={'applicationType'}
                        name="applicationType"
                        label={i18n._({
                            id: 'createApplication.selectTypeLabel',
                        })}
                        options={transactionTypeOptions}
                        required
                    />
                    <FormActions label="createApplication.submit" />
                </Box>
            </FormProvider>
        </Card>
    );
};

export default CreateApplicationForm;
