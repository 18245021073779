import React, { Suspense } from 'react';

import { Trans } from '@lingui/react';
import { useTenantFlags } from '@nestoca/multi-tenant';
import css from '@styled-system/css';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { compose } from 'recompose';
import { Box, Flex } from 'reflexbox/styled-components';

import { ApplicationSelector } from 'components/application-selector';
import { Button } from 'components/button-v2';
import { Card } from 'components/card/new-card';
import { Property, SelectedProductState } from 'components/dashboard';
import { BestProducts } from 'components/dashboard/broker-dashboard/best-products';
import { ClientSnapshot } from 'components/dashboard/broker-dashboard/client-snapshot';
import CreateApplicationForm from 'components/dashboard/create-application';
import { DashboardHeader } from 'components/dashboard-header/dashboard-header';
import { Emoji } from 'components/emoji';
import { Grid } from 'components/grid/grid';
import { EntitiesModalWrapper } from 'components/modals/entities-modal-wrapper';
import { Text } from 'components/text/text';
import { ZERO } from 'constants/appConstants';
import authenticated from 'hoc/authenticated';
import authorized from 'hoc/authorized';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    applicationListState,
    selectedApplication,
    getProductSelectionState,
} from 'store/applications';
import { getSelectedDashboard } from 'store/dashboard';
import { getNotes } from 'store/notes';
import { useEditingRights } from 'store/rights';
import { isContextualSidebarOpenState } from 'store/ui';
import { useEditing } from 'utils/use-editing';
import { EditingProvider } from 'utils/use-editing-context';

import { SidebarDashboard } from './sidebar-dashboard';
import { UnderWriterDashboard } from './underwriter-dashboard/underwriter-dashboard';

const BrokerDashboardView = () => {
    const router = useRouter();
    const { enableApplicationCreationInternal } = useTenantFlags();

    // Recoil Dependency
    const applications = useRecoilValue(applicationListState);
    const application = useRecoilValue(selectedApplication);
    const activeDashboard = useRecoilValue(getSelectedDashboard);

    if (applications?.length === ZERO) {
        if (!enableApplicationCreationInternal) {
            router.push('/forbidden');
        } else return <CreateApplicationForm />;
    }

    if (!application) {
        return <NoApplicationSelected />;
    }

    return activeDashboard === 'ADVISOR' ? (
        <ApplicationSelectedDashboard />
    ) : (
        <UnderWriterDashboard />
    );
};

const ApplicationSelectedDashboard = () => {
    const router = useRouter();
    const { i18n } = useI18n();
    const application = useRecoilValue(selectedApplication);
    useRecoilValue(getNotes({ applicationId: application.id }));
    const editing = useEditing();
    const { productSelectionState } = useFlags();
    const hasEditingRights = useEditingRights();
    const isContextualSidebarOpen = useRecoilValue(
        isContextualSidebarOpenState
    );
    const productSelectionStateLength = useRecoilValue(
        getProductSelectionState(application.id)
    ).selectionQuestions.length;

    const productSelectorEnabled =
        hasEditingRights &&
        (application?.applicationState === 'SUBMITTED' ||
            application?.applicationState === 'UNDER_REVISION');

    return (
        <EditingProvider {...editing}>
            <EntitiesModalWrapper>
                <Grid
                    gridTemplateColumns={
                        isContextualSidebarOpen ? 'auto 350px' : 'auto 10px'
                    }
                    gridGap={20}
                >
                    <Grid>
                        <Box mt={4}>
                            <ClientSnapshot />
                        </Box>
                        <DashboardHeader />
                        <BestProducts applicationId={application.id} />

                        <Grid
                            gridTemplateColumns={[
                                '2fr',
                                null,
                                'repeat(2, 1fr)',
                            ]}
                            gridAutoRows="min-content max-content"
                            gridAutoFlow="row"
                            gridGap={'10px 20px'}
                        >
                            {productSelectionState && (
                                <Box
                                    css={css({
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'column',
                                    })}
                                >
                                    <Card
                                        css={css({
                                            flexDirection: 'column',
                                            width: '100%',
                                            boxShadow: ['md', '2xl'],
                                            marginTop: 3,
                                            height: '100%',
                                        })}
                                    >
                                        <Flex
                                            css={css({
                                                justifyContent: 'space-between',
                                                alignItems: 'baseline',
                                            })}
                                        >
                                            <Text
                                                as="h2"
                                                css={{ marginBottom: 0 }}
                                            >
                                                <Emoji
                                                    symbol="🤲🏼"
                                                    label="Customer Needs"
                                                    css={{ marginRight: 10 }}
                                                />
                                                <Trans id="dashboardHeader.customerNeeds" />
                                            </Text>
                                            {productSelectionStateLength >
                                                0 && (
                                                <Button
                                                    onClick={() =>
                                                        router.push(
                                                            `applications/${application.id}/product-selection`
                                                        )
                                                    }
                                                    disabled={
                                                        !productSelectorEnabled
                                                    }
                                                >
                                                    {i18n._(`start`)}
                                                </Button>
                                            )}
                                        </Flex>
                                        <Box css={{ marginLeft: 31 }}>
                                            <Text
                                                tx="dashboardHeader.customerNeeds.caption"
                                                fontSize={0}
                                                color="gray-2"
                                            />
                                        </Box>
                                        <SelectedProductState />
                                        {productSelectionStateLength === 0 && (
                                            <Flex
                                                css={css({
                                                    justifyContent: 'center',
                                                    marginTop: 4,
                                                })}
                                            >
                                                <Button
                                                    onClick={() =>
                                                        router.push(
                                                            `applications/${application.id}/product-selection`
                                                        )
                                                    }
                                                    disabled={
                                                        !productSelectorEnabled
                                                    }
                                                >
                                                    {i18n._(
                                                        `startProductSelection`
                                                    )}
                                                </Button>
                                            </Flex>
                                        )}
                                    </Card>
                                </Box>
                            )}

                            <Box
                                css={css({
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                })}
                            >
                                <Card
                                    css={css({
                                        flexDirection: 'column',
                                        width: '100%',
                                        boxShadow: ['md', '2xl'],
                                        marginTop: 3,
                                        height: '100%',
                                    })}
                                >
                                    <Text as="h2" css={{ marginY: 10 }}>
                                        <Emoji
                                            symbol="🏡"
                                            label="property"
                                            css={{ marginRight: 10 }}
                                        />
                                        <Trans id="Property" />
                                    </Text>
                                    <Property />
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid>
                        <SidebarDashboard applicationId={application.id} />
                    </Grid>
                </Grid>
            </EntitiesModalWrapper>
        </EditingProvider>
    );
};

export const NoApplicationSelected = () => (
    <Flex
        id="no-application-selected"
        css={{
            justifyContent: 'center',
            width: '100%',
            height: '100%',
        }}
    >
        <ApplicationSelector outsideOfModal />
    </Flex>
);

// BrokerDashboardContainer suspense wait for promise dependencies to be resolve before rendering
const BrokerDashboardContainer = () => (
    <Suspense fallback={null}>
        <BrokerDashboardView />
    </Suspense>
);

export const BrokerDashboard: React.FC = compose(
    authenticated,
    authorized('broker')
)(BrokerDashboardContainer);
