import { useEffect } from 'react';

import { Card, Chip, Divider, Flex } from '@nestoca/ui';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import styles from 'components/dashboard/dashboard-progress-cards.module.scss';
import { ProgressCircle } from 'components/progress-circle/progress-circle';
import { Text } from 'components/text/text';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    getProblemsSectionsWithCount,
    getProblemsValidationMode,
    selectedApplication,
} from 'store/applications';
import { useTheme } from 'utils/use-theme';

import { Sections } from './sections';
import { getMissingTotal, getProgress } from './utils';

export const CustomerDataCardContainer = () => {
    const { i18n } = useI18n();
    const { colors } = useTheme();
    const { id: applicationId } = useRecoilValue(selectedApplication);

    const setProblemsValidationState = useSetRecoilState(
        getProblemsValidationMode
    );

    const sections = useRecoilValue(
        getProblemsSectionsWithCount({ applicationId })
    );

    useEffect(() => {
        setProblemsValidationState(true);
    }, []);

    const missingTotal = getMissingTotal(sections);
    const progress = getProgress(sections, i18n);

    return (
        <Card className={styles.card}>
            <Flex
                className={styles.header}
                css={{ position: 'sticky', top: 0 }}
            >
                <Flex className={styles.leftFlex}>
                    <ProgressCircle
                        borderColor={colors['neutral-3']}
                        value={progress}
                        size={50}
                        text={`${progress}%`}
                    />
                    <Text tx="customerData" fontWeight="700" fontSize="16px" />
                </Flex>
                <Chip
                    label={`${missingTotal} ${i18n._(
                        'DOCUMENT_LENDER_MISSING'
                    )}`}
                />
            </Flex>
            <Divider css={{ marginBottom: 0 }} />
            <Sections applicationId={applicationId} />
        </Card>
    );
};
