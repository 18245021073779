import React, { useMemo } from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import Head from 'next/head';
import { compose } from 'recompose';

import { UserDashboard } from 'components/dashboard';
import { DashboardSidebar } from 'components/dashboard/dashboard-sidebar';
import { LayoutWithSidebar } from 'components/layout/layout-with-sidebar';
import authorized from 'hoc/authorized';
import { tenantServerProps } from 'libs/tenant-server-props';
import { useAuth } from 'providers/auth';
import { useI18n } from 'providers/i18n/use-i18n';

import type { GetServerSideProps, NextPage } from 'next';

export const Home = () => {
    const { i18n } = useI18n();
    const { token, getRole } = useAuth();

    const role = useMemo(() => token && getRole(), [getRole, token]);

    const getSecondarySidebar = () => {
        if (role !== 'broker') {
            return null;
        }

        return <DashboardSidebar />;
    };

    return (
        <LayoutWithSidebar secondarySidebar={getSecondarySidebar()}>
            <Head>
                <title>{i18n._('Dashboard')}</title>
            </Head>
            <UserDashboard />
        </LayoutWithSidebar>
    );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
    return tenantServerProps(context);
};

const Page: NextPage = compose(
    withAuthenticationRequired,
    authorized(['broker'])
)(Home);

export default Page;
