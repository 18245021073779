import { Fragment } from 'react';

import { Card, Divider, Flex } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';
import { Box } from 'reflexbox/styled-components';

import styles from 'components/dashboard/dashboard-progress-cards.module.scss';
import { ProgressCircle } from 'components/progress-circle/progress-circle';
import { ScrollArea } from 'components/scroll-area';
import { Text } from 'components/text/text';
import { ONE_HUNDRED } from 'constants/appConstants';
import { getApplicantsByIds, selectedApplication } from 'store/applications';
import {
    applicationDocumentsCounts,
    getSelectedDocumentType,
} from 'store/documents';
import { getSelectedApplicantsIds } from 'store/qualification';
import { useTheme } from 'utils/use-theme';

import { CounterFlagsContainer } from './counter-flag.container';
import { DocumentsSections } from './document-sections';

type Props = {
    underwriterDashboard?: boolean;
};

export const DocumentsCardContainer = ({ underwriterDashboard }: Props) => {
    const { colors } = useTheme();
    const application = useRecoilValue(selectedApplication);
    const applicantsIds = useRecoilValue(
        getSelectedApplicantsIds({ applicationId: application.id })
    );
    const docStep = useRecoilValue(
        getSelectedDocumentType({ applicationId: application.id })
    );
    const applicants = useRecoilValue(
        getApplicantsByIds({ applicationId: application.id, applicantsIds })
    );
    const documentsCount = useRecoilValue(
        applicationDocumentsCounts({
            applicationId: application.id,
            step: docStep,
        })
    );
    const { receivedCount, totalCount } = documentsCount;
    const recievedDocumentsProgress =
        (receivedCount / totalCount) * ONE_HUNDRED;

    return (
        <Card
            className={
                underwriterDashboard
                    ? `${styles.card} ${styles.cardUnderwriter}`
                    : styles.card
            }
        >
            <Flex className={styles.header}>
                <Flex className={styles.leftFlex}>
                    <ProgressCircle
                        borderColor={colors['neutral-3']}
                        value={recievedDocumentsProgress}
                        size={50}
                        text={`${receivedCount} / ${totalCount}`}
                    />
                    <Text
                        tx="dashboardHeader.documents"
                        fontWeight="700"
                        fontSize="16px"
                    />
                </Flex>
            </Flex>
            <Divider css={{ marginBottom: 0 }} />
            <ScrollArea
                css={{
                    '&:hover': {
                        overflowY: 'scroll',
                    },
                }}
            >
                <Box css={{ padding: '0 var(--spacing-4) var(--spacing-7)' }}>
                    <CounterFlagsContainer documentsCount={documentsCount} />
                    {applicants.map((applicant) => {
                        return (
                            <Fragment key={applicant.applicantId}>
                                <Text
                                    as="h4"
                                    my={'16px'}
                                    tx={`${applicant.firstName} ${applicant.lastName}`}
                                />
                                <DocumentsSections
                                    applicationId={application.id}
                                    applicantId={applicant.applicantId}
                                />
                            </Fragment>
                        );
                    })}
                </Box>
            </ScrollArea>
        </Card>
    );
};
