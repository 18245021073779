import { Fragment } from 'react';

import { Card, Divider, Flex, Grid } from '@nestoca/ui';
import cn from 'classnames';
import { BsFilePerson } from 'react-icons/bs';
import { useRecoilValue } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { Text } from 'components/text/text';
import { getReferralConsultantContacts } from 'store/referrals';

import dashBoardStyles from '../broker-dashboard.module.scss';

import styles from './consultant-contact.module.scss';

type ConsultantContactProps = {
    applicationId: number;
};

export const ConsultantContact = ({
    applicationId,
}: ConsultantContactProps) => {
    const consultantContacts = useRecoilValue(
        getReferralConsultantContacts({ applicationId })
    );

    return (
        <>
            <Card
                className={cn(
                    styles['consultant-contact__card'],
                    dashBoardStyles.card
                )}
                title={'asset.property'}
            >
                <Flex
                    className={dashBoardStyles.header}
                    align="center"
                    justify="between"
                    gap={4}
                    as="header"
                >
                    <Flex align="center" gap={2}>
                        <BsFilePerson size={30} />
                        <Text
                            fontWeight={'bold'}
                            tx={'referral.consultantInfo'}
                        />
                    </Flex>
                </Flex>
                <Divider className={dashBoardStyles['main-divider']} />
                <Flex className={styles.body} direction="column">
                    {consultantContacts.map((contact) => (
                        <Fragment key={contact.email}>
                            <Grid className={styles.grid}>
                                <CellData
                                    tx={'First Name'}
                                    value={contact.firstName || ''}
                                />
                                <CellData
                                    tx={'Last Name'}
                                    value={contact.lastName || ''}
                                />
                                <CellData
                                    tx={'email'}
                                    value={contact.email || ''}
                                    withCopy
                                />
                                <CellData
                                    tx={'Phone'}
                                    value={contact.phone || ''}
                                />
                            </Grid>
                            <Divider />
                            <Grid className={styles.grid}>
                                <CellData
                                    tx={'referral.department'}
                                    value={
                                        contact.externalData?.department || ''
                                    }
                                />
                                <CellData
                                    tx={'referral.id'}
                                    value={contact.externalData?.id || ''}
                                />
                                <CellData
                                    tx={'referral.title'}
                                    value={contact.externalData?.title || ''}
                                />
                                <CellData
                                    tx={'referral.username'}
                                    value={contact.externalData?.username || ''}
                                />
                            </Grid>
                        </Fragment>
                    ))}
                </Flex>
            </Card>
        </>
    );
};
