import { Suspense } from 'react';

import { Trans } from '@lingui/react';
import { Grid, Heading } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import { getReferralByApplicationId } from 'store/referrals';

import styles from './broker-dashboard-ig.module.scss';
import { ConsultantContact } from './consultant-contact';
import { MainApplicant } from './main-applicant';
import { OtherMortgages } from './other-mortgages';
import { ReferrerContact } from './referrer-contact';

interface BrokerDashboardIGProps {
    applicationId: number;
}

export const BrokerDashboardIGView = ({
    applicationId,
}: BrokerDashboardIGProps) => {
    useRecoilValue(getReferralByApplicationId({ applicationId }));

    return (
        <>
            <Heading as="h2" size={5} weight={4}>
                <Trans id="referral.sectionTitle" />
            </Heading>
            <Grid className={styles['dashboard-grid']} gap={2}>
                <MainApplicant applicationId={applicationId} />
                <ConsultantContact applicationId={applicationId} />
                <ReferrerContact applicationId={applicationId} />
                <OtherMortgages applicationId={applicationId} />
            </Grid>
        </>
    );
};

export const BrokerDashboardIG = ({
    applicationId,
}: BrokerDashboardIGProps) => {
    return (
        <Suspense fallback={null}>
            <BrokerDashboardIGView applicationId={applicationId} />
        </Suspense>
    );
};
