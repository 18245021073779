import { Divider, Grid } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import {
    INTEREST_RATE_TYPE,
    MORTGAGE_TYPE,
    PROPERTY_TYPE,
    TERM_TYPE,
} from 'components/dashboard-header/components/application-info';
import { PROPERTY_PURPOSE_LABEL } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { getPropertySelectors } from 'store/applications';
import { formatDate } from 'utils/property';
import { useIsTenant } from 'utils/useIsTenant';

import { CellData } from '../../applicants/components/cell-data';

export const NewMortgage = () => {
    const { i18n } = useI18n();
    const {
        application: { property, mortgage },
        sumAssets,
        mainApplicant,
        closingCost,
        applicantSumAssets,
        mortgageAmount,
        ownedProperties,
    } = useRecoilValue(getPropertySelectors);

    // ******************************************************************************************
    // ****** TODO: THIS SHOULD BE DEPRECATED IN FAVOR OF THE NEW TENANT FEATURES FLAG    *******
    // ****** [epic: SEAL-787](https://nestoca.atlassian.net/browse/SEAL-787)             *******
    // ******                                                                             *******
    // ****** Please be aware that the new tenant features flag is not yet implemented    *******
    // ****** if you have request to add more tenant slug to this condition.              *******
    // ****** remind your P.O. we should build the tenant specific features flag project. *******
    // ****** We want to keep thing scalable and maintainable.                            *******
    // ******                                                                             *******
    // ******************************************************************************************
    const { isIGTenant } = useIsTenant();

    return (
        <>
            <Grid css={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <CellData
                    tx={'dashboardHeader.propertyType'}
                    value={PROPERTY_TYPE(i18n)[property.propertyType]}
                />
                {isIGTenant && (
                    <CellData
                        tx={'propertyTypeOverride'}
                        value={property.propertyTypeOverride}
                    />
                )}
                <CellData
                    tx={`purpose`}
                    value={PROPERTY_PURPOSE_LABEL(i18n)[property.purpose]}
                />
                <CellData
                    fieldType="money"
                    tx={`purchasePrice`}
                    value={property.purchasePrice}
                />
                <CellData
                    fieldType="money"
                    tx={'totalAssets'}
                    value={sumAssets.value}
                />
                <CellData
                    fieldType="money"
                    tx={'totalDownpayment'}
                    value={applicantSumAssets.amountUsedForDownPayment}
                />
                <CellData
                    fieldType="money"
                    tx={'closingCost'}
                    value={closingCost}
                />
                <CellData
                    fieldType="money"
                    tx={'mortgageAmount'}
                    value={mortgageAmount.amount}
                />
            </Grid>
            <Divider />
            <Grid css={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <CellData
                    tx={'dashboardHeader.propertyFound'}
                    value={property.isFound ? i18n._('yes') : i18n._('no')}
                />
                <CellData tx={'otherIncome'} fieldType="money" value={'0'} />
                <CellData
                    tx={'dashboardHeader.firstTimeBuyer'}
                    value={
                        mainApplicant.firstTimeHomeBuyer
                            ? i18n._('yes')
                            : i18n._('no')
                    }
                />
                <CellData
                    tx={`purpose`}
                    value={PROPERTY_PURPOSE_LABEL(i18n)[property.purpose]}
                />
                <CellData
                    tx={'mortgageType'}
                    value={
                        MORTGAGE_TYPE(i18n)[
                            property?.mortgages[0]?.mortgageType
                        ]
                    }
                />
                <CellData
                    tx={'rateType'}
                    value={
                        INTEREST_RATE_TYPE(i18n)[
                            property?.mortgages[0]?.interestRateType
                        ]
                    }
                />
                <CellData
                    tx={'termType'}
                    value={TERM_TYPE(i18n)[property?.mortgages[0]?.termType]}
                />
                <CellData
                    tx={'originallyInsured'}
                    value={
                        property?.mortgages[0]?.originallyInsured
                            ? i18n._('yes')
                            : i18n._('no')
                    }
                />
                <CellData
                    tx={'dashboardHeader.closingDate'}
                    value={formatDate(mortgage?.closingDate, i18n.locale)}
                />
                <CellData
                    fieldType="date"
                    tx={`acceptanceDate`}
                    value={property.acceptanceDate}
                />
            </Grid>
            <Divider />
            <Grid css={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <CellData tx="ownedProperties" value={ownedProperties.length} />
            </Grid>
        </>
    );
};
